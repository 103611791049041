<template>
  <section :class="sectionClass">
    <div class="site-width">
      <SectionHeading title="Curated by Unrivaled Experts" is-flush>
        <template #titleSlot>
          <h2>
            Curated by
            <br />
            Unrivaled Experts
          </h2>
        </template>
      </SectionHeading>
      <CardCarousel
        id="expert-carousel"
        :item-count="4"
        hide-pagination
        hide-arrows
        extra-classes="card_carousel__experts"
        :one-fourth-width-card="true"
      >
        <li>
          <span class="image-wrap">
            <span class="image card__image">
              <picture class="lazyLoad">
                <img
                  alt="Portrait of Lucero"
                  role="presentation"
                  class="lazyLoad"
                  v-lazy-load data-src="~/assets/images/team-lucero-cropped.jpg"
                />
              </picture>
            </span>
          </span>
          <h3 class="card__title">
            Sur Lucero
          </h3>
          <h4>Master Sommelier</h4>
          <p class="description">
            Sur is the 14th Krug Cup recipient, passing all three sections of
            the Master Sommelier exam on his first attempt. He honed his palate
            working at The French Laundry, The Little Nell, Cyrus, Meadowood
            Resort, and Daniel Boulud Las Vegas.
          </p>
        </li>
        <li>
          <span class="image-wrap">
            <span class="image card__image">
              <picture class="lazyLoad">
                <img
                  alt="Portrait of Laura"
                  role="presentation"
                  class="lazyLoad"
                  v-lazy-load data-src="~/assets/images/team-koffer-cropped.jpg"
                />
              </picture>
            </span>
          </span>
          <h3 class="card__title">
            Laura Koffer
          </h3>
          <h4>Advanced Sommelier</h4>
          <p class="description">
            Laura spearheaded the wine program for Top Chef Masters Michael
            Chiarello’s restaurant group and was a manager at Wine Spectator
            award-winning TORC.
          </p>
        </li>
        <li>
          <span class="image-wrap">
            <span class="image card__image">
              <picture class="lazyLoad">
                <img
                  alt="Portrait of Eduardo"
                  role="presentation"
                  class="lazyLoad"
                  v-lazy-load data-src="~/assets/images/team-digler-cropped.jpg"
                />
              </picture>
            </span>
          </span>
          <h3 class="card__title">
            Eduardo Dingler
          </h3>
          <h4>Wine Judge and Sommelier</h4>
          <p class="description">
            Eduardo was the Global Beverage Director for the Morimoto Group for
            nearly a decade, has worked at some of Napa Valley’s most iconic
            restaurants, and is a professional Wine, Sake, and Spirits Judge and
            Journalist.
          </p>
        </li>
      </CardCarousel>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ExpertsSection',
  components: {
    CardCarousel: () => import('~/components/CardCarousel'),
    SectionHeading: () => import('~/components/SectionHeading'),
  },
  props: {
    extraClasses: {
      type: String,
      default: '',
    },
  },
  computed: {
    sectionClass() {
      return `experts ${this.extraClasses}`
    },
  },
}
</script>

<style scoped></style>
